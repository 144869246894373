<template>
  <Menu as="div" class="relative">
    <MenuButton class="-m-1.5 flex items-center p-1.5">
      <span class="sr-only">Open user menu</span>
      <span
        v-if="!user.image"
        class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500"
      >
        <span class="text-sm font-medium leading-none text-white">
          {{ extractInitials({ value: user?.name || '' }) }}
        </span>
      </span>
      <img
        v-if="user.image"
        class="h-8 w-8 rounded-full bg-gray-50"
        :src="user.image"
        alt=""
      />
      <span class="hidden lg:flex lg:items-center">
        <BaseIcon
          name="heroicons:chevron-down"
          class="ml-2 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute divide-y divide-gray-100 right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
      >
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="/settings/profile"
              class="flex gap-x-3 items-center"
              :class="activeClass(active)"
            >
              <BaseIcon name="heroicons:user" />
              Profile
            </a>
          </MenuItem>
          <MenuItem v-slot="{ active, close }">
            <a
              href="/settings"
              class="flex gap-x-3 items-center"
              :class="activeClass(active)"
              @click="close"
            >
              <BaseIcon name="heroicons:cog" />
              Settings
            </a>
          </MenuItem>
          <template v-if="canSeeBilling">
            <MenuItem v-slot="{ active, close }">
              <a
                href="/settings/billing"
                class="flex gap-x-3 items-center"
                :class="activeClass(active)"
                @click="close"
              >
                <BaseIcon name="heroicons:credit-card" />
                Billing
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active, close }">
              <a
                href="/settings/users"
                class="flex gap-x-3 items-center"
                :class="activeClass(active)"
                @click="close"
              >
                <BaseIcon name="heroicons:user-group" />
                Team
              </a>
            </MenuItem>
          </template>
          <MenuItem v-slot="{ active }">
            <BaseLink
              class="flex gap-x-3 items-center cursor-pointer"
              :class="activeClass(active)"
              @click="handleImportPrompts"
            >
              <BaseIcon name="heroicons:document-arrow-up" />
              Import Prompts
            </BaseLink>
          </MenuItem>
          <template v-if="!user.extension_installed">
            <MenuItem v-slot="{ active }">
              <a
                href="https://chrome.google.com/webstore/detail/promptdrive/dccfgkejoobijclmdfefmigflabbjcbg"
                class="flex gap-x-3 items-center"
                :class="activeClass(active)"
              >
                <BaseIcon name="heroicons:puzzle-piece" />
                Chrome Extension
              </a>
            </MenuItem>
          </template>
        </div>
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              class="flex gap-x-3 items-center"
              :class="activeClass(active)"
              @click.prevent="handleSignOut"
            >
              <BaseIcon name="heroicons:arrow-left-on-rectangle" />
              Sign Out
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import useCurrentUser from '~/composables/useCurrentUser'
import extractInitials from '~/utils/extractInitials'
import useAuthStore from '~/stores/useAuthStore'

const emit = defineEmits(['search'])
const authStore = useAuthStore()
const canSeeBilling = authStore.can('ownerOrAdmin')
const { user, signOut } = useCurrentUser()
const activeClass = (active: boolean) => {
  return [
    active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
    'block px-4 py-2 text-sm rounded',
  ]
}
const showUserSettings = ref(false)

const handleSignOut = () => {
  signOut({ callbackUrl: '/login' })
}

const handleSettings = () => {
  showUserSettings.value = true
}

const handleImportPrompts = () => {
  useEventBus({ name: EVENTS.IMPORT_PROMPTS }).emit({ show: true })
}
</script>
