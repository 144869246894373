const extractInitials = ({ value }: { value: string }): string => {
  const words = value.trim().split(' ')
  let initials = ''

  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      initials += words[i][0].toUpperCase()

      if (initials.length === 2) {
        break
      }
    }
  }

  return initials
}

export default extractInitials
